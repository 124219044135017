import { List, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

const faqData = [
  {
    category: 'Services Offered by 1Lifeline',
    questions: [
      'What are the payment options for my 1Lifeline membership?',
      'Can I get reimbursement through my insurance?',
      'Will conversations with my therapist be kept confidential?',
      'How does 1Lifeline ensure my privacy and security?',
      'How do I begin using 1Lifeline?',
      'Can 1Lifeline provide therapy services for my pet?',
      'As a licensed therapist, how can I offer my services through 1 Lifeline?',
    ]
  }
];

export default function FAQ3() {
  return (
    <List>
      {faqData.map((category, index) => (
        <React.Fragment key={index}
          sx={{
            width: '90%'
          }}
        >
          {category.questions.map((question, qIndex) => (
            <Accordion sx={{
              boxShadow: 'none',
              width: '90%',
              mb: 2,
              color: '#5b5d6c',
              '@media (max-width: 425px)': {
                margin: 'auto',
                mb: 2,
                width: '100%'
              },
              '&::before': {
                display: 'none'
              },
            }} key={qIndex} >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#167CC8' }} />}
                aria-controls="panel1-content"
                id="panel1-header" sx={{
                  boxShadow: '2px 0px 2px 0px rgba(0,0,0,0.1),0px 0px 15px -3px rgba(0,0,0,0.1)',
                  border: 'none',
                }}>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '1.125rem', fontWeight: '500', textAlign: 'left' }}>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {question === 'What are the payment options for my 1Lifeline membership?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    We offer convenient payment options for your 1Lifeline membership. Depending on your
                    membership commitment type/status, payments can be charged to a credit card or PayPal
                    account for ease and security.
                  </Typography>
                ) : question === 'Can I get reimbursement through my insurance?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    1Lifeline will accept insurance plans.
                  </Typography>
                ) : question === 'Will conversations with my therapist be kept confidential?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    At 1 Lifeline, we treat your conversations with our therapists with the highest level of
                    confidentiality. Our therapists are licensed and board-certified, adhering to mandated
                    reporting and confidentiality requirements. However, there are exceptions to ensure
                    safety: If you express a threat to harm yourself or others, our therapists are obligated to
                    report this to the authorities, prioritizing your safety and the safety of all individuals
                    involved.
                  </Typography>
                ) : question === 'How does 1Lifeline ensure my privacy and security?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    Information about patients is protected by data privacy laws and we adhere to HIPAA
                    compliance.
                  </Typography>
                ) : question === 'How do I begin using 1Lifeline?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    To begin using 1Lifeline, sign up using our form and wait for an email for an account
                    creation. There will be instructions and you should be ready to go.
                  </Typography>
                ) : question === 'Can 1Lifeline provide therapy services for my pet?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    Unfortunately, 1 Lifeline does not currently offer therapy services for animals. Maybe
                    sometime in the future!
                  </Typography>
                ) : question === 'As a licensed therapist, how can I offer my services through 1 Lifeline?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    At 1 Lifeline, our mission is to empower our clients on their path toward healing and
                    fostering positive transformations, allowing them to lead happier, healthier, and more
                    fulfilling lives. We achieve this by providing diverse services tailored to meet our patients’
                    unique needs and cultivating a safe space for them to thrive.
                    As part of our team, you'll have the opportunity to make a lasting impact in the lives of
                    those we serve and contribute to their overall well-being and growth. Join us in creating a
                    compassionate and supportive community where our patients can find the guidance they
                    need to flourish.
                    If you’re excited about the opportunity to change mental health care in a meaningful way,
                    click here to get started with your application!
                  </Typography>
                ) : null}
              </AccordionDetails>
            </Accordion>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
}