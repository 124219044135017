import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import TherapistsImg from '../images/hero-image 1.png';

const AboutUs = () => {
    const PhotoContainer = styled(Grid)({
        borderRadius: '8px 0 0 8px',
        textAlign: 'center',
        width: 'fit-content',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    });

    const TextContainer = styled(Grid)({
        backgroundColor: '#264E36',
        color: 'white',
        borderRadius: '0 8px 8px 0',
        padding: '5vw 2vw',
        textAlign: 'left',
        '@media (max-width: 768px)': {
            width: '100%',
            borderRadius: '0.5rem',
        },
    });

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '88vw',
                    margin: '2rem auto',
                }}
            >
                <Grid container spacing={0}>
                    <PhotoContainer item xs={12} md={4}>
                        <img
                            src={TherapistsImg}
                            alt="Group of people"
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px 0 0 8px' }}
                        />
                    </PhotoContainer>
                    <TextContainer item xs={12} md={8}>
                        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Inter' }}>
                            Your Safe Haven for Online Therapy
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ fontFamily: 'Inter' }}>
                            We recognize the courage it takes to seek therapy and the emotional journey it entails.
                            Our commitment is to offer a secure and private environment for individuals navigating
                            through this process. Our online platform is designed to offer flexibility and
                            convenience, ensuring that therapy is more accessible without the limitations of
                            traditional face-to-face sessions.
                        </Typography>
                    </TextContainer>
                </Grid>
            </div>
        </>
    );
};

export default AboutUs;