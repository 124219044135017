import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound from './Pages/PageNotFound';
import Homepage from './Pages/homePage';
import ConnectUsPage from './Components/Contact';
import FAQPage from './Pages/FAQPage';

function App() {
  return (
    <div className="App" >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="#contact" element={<ConnectUsPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;