import React, { useState } from 'react';
import { List, ListItem, Typography, Grid } from '@mui/material';
import FAQ1 from './FAQ1';
import FAQ2 from './FAQ2';
import FAQ3 from './FAQ3';

const faqData = [
  {
    category: 'Services Offered by 1Lifeline',
    component: <FAQ1 />
  },
  {
    category: 'Therapist Information and Support',
    component: <FAQ2 />
  },
  {
    category: 'Membership and Platform Information',
    component: <FAQ3 />
  }
];

export default function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleListItemClick = (index) => {
    setActiveIndex(index);
    console.log(`Active index set to ${activeIndex}`);
  };

  return (

    <Grid container>
      <Grid item sm={5}>
        <List sx={{
          fontFamily: 'Inter',
          fontSize: '1.5rem',
          fontWeight: '500',
        }}>
          {faqData.map((category, index) => (
            <React.Fragment key={index}>
              <ListItem button onClick={() => handleListItemClick(index)}>
                <Typography sx={{
                  fontFamily: 'Inter',
                  fontSize: '1.25rem',
                  fontWeight: '500',
                  cursor: 'pointer',
                  padding: '0.75rem 0',
                  margin: '0 0 0 5.25rem',
                  '@media (max-width: 425px)': {
                    margin: '0 0 0 1.25rem',
                  },
                  borderBottom: activeIndex === index ? '2px solid #097191' : 'none',
                  color: activeIndex === index ? '#097191' : '#5b5d6c'
                }} vriant="h6">{category.category}</Typography>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <Grid item sm={7}>
        {activeIndex !== null && faqData[activeIndex].component}
      </Grid>
    </Grid>
  );
}