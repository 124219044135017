import React from 'react';
import '../css/btn.css';

const CustomBtn = ({ text }) => {
    return (
        <button className="btn">
            <span>{text}</span>
            <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0.0720215C16 0.814021 16.733 1.92202 17.475 2.85202C18.429 4.05202 19.569 5.09902 20.876 5.89802C21.856 6.49702 23.044 7.07202 24 7.07202M24 7.07202C23.044 7.07202 21.855 7.64702 20.876 8.24602C19.569 9.04602 18.429 10.093 17.475 11.291C16.733 12.222 16 13.332 16 14.072M24 7.07202L0 7.07202" stroke="#002936" />
            </svg>
            <div class="background"></div>
        </button>
    );
};

export default CustomBtn;