import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import '../css/fonts.css';

const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRight: '1px solid #E0E0E0',
    '&:last-child': {
        borderRight: 'none',
    },
}));

const NumberText = styled(Typography)(({ theme }) => ({
    fontSize: '6em',
    color: '#64C3D9',
    fontWeight: 'bold',
    fontFamily: 'Recoleta, Arial, sans-serif',
    '@media (max-width: 930px)': {
        fontSize: '4.5em',
    },
    '@media (max-width: 750px)': {
        fontSize: '3.5em',
    },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
    fontSize: '1.25em',
    color: '#666',
    fontFamily: 'Inter',
    fontWeight: 500,
    '@media (max-width: 930px)': {
        fontSize: '0.938em',
    },
    '@media (max-width: 750px)': {
        fontSize: '0.738em',
    },
}));

const StatisticsContainer = styled(Grid)(({ theme }) => ({
    width: '90%',
    margin: '3em auto',
}));

function StatisticsSection() {
    return (
        <StatisticsContainer container>
            <Grid item xs={6} sm={3}>
                <StyledBox>
                    <NumberText>+30</NumberText>
                    <DescriptionText>Years Expertise</DescriptionText>
                </StyledBox>
            </Grid>
            <Grid item xs={6} sm={3}>
                <StyledBox>
                    <NumberText>+45</NumberText>
                    <DescriptionText>Medical Branches</DescriptionText>
                </StyledBox>
            </Grid>
            <Grid item xs={6} sm={3}>
                <StyledBox>
                    <NumberText>154</NumberText>
                    <DescriptionText>In-House Experts</DescriptionText>
                </StyledBox>
            </Grid>
            <Grid item xs={6} sm={3}>
                <StyledBox>
                    <NumberText>1510</NumberText>
                    <DescriptionText>Success Stories</DescriptionText>
                </StyledBox>
            </Grid>
        </StatisticsContainer>
    );
}

export default StatisticsSection;