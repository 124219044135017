import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Image1 from '../images/1.1.png';
import Image2 from '../images/1.2.png';
import Image3 from '../images/1.3.png';

const StyledPaper = styled(Paper)(({ theme, backgroundColor }) => ({
    height: '30.375rem',
    borderRadius: '15px',
    backgroundColor: backgroundColor,
    color: '#FEFEFE',
}));

const ServiceItem = ({ imageSrc, altText, title, description, backgroundColor }) => {
    return (
        <StyledPaper backgroundColor={backgroundColor}>
            <img src={imageSrc} alt={altText} style={{ width: '100%', height: 'auto', borderRadius: '15px' }} />
            <Typography variant="h5" component="h2" gutterBottom sx={{
                fontSize: '1.125rem', fontWeight: '600', fontFamily: 'Inter', textAlign: 'left', padding: '1.5rem 0 1.25rem 2rem',
                '@media (max-width: 425px)': {
                    padding: '1rem 0 1rem 1rem',
                    margin: '0',
                }
            }}>
                {title}
            </Typography>
            <Typography variant="body1" sx={{
                fontSize: '1rem', fontWeight: '300', fontFamily: 'Inter', textAlign: 'left', padding: '0 1.5rem 0 1.5rem', lineHeight: '1.2',
                '@media (max-width: 425px)': {
                    padding: '0 1rem 0 1rem',
                }
            }}>
                {description}
            </Typography>
        </StyledPaper>
    );
};

const ServiceSection = () => {
    return (
        <div style={{ padding: '5vh 0', width: '90%', margin: '2rem auto' }} >
            <Typography id='Services' variant="h1" component="h1" sx={{ fontFamily: 'Inter', fontSize: '1.75rem', fontWeight: '600', color: '#303138', textAlign: 'left', padding: '2vh 0', margin: '0 0 2.5rem 0' }}>
                Services
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{
                backgroundColor: '#FEFEFE',
            }}>
                <Grid item xs={12} sm={6} md={3}>
                    <StyledPaper backgroundColor="#963A0F">
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: '700',
                                fontFamily: 'Inter',
                                padding: '7.469rem 0 1.25rem 2rem',
                                textAlign: 'left',
                                '@media (max-width: 1024px)': {
                                    padding: '4rem 0 1.25rem 1rem',
                                },
                                '@media (max-width: 768px)': {
                                    padding: '7.469rem 0 1.25rem 2rem',
                                },
                            }}
                        >
                            What We Offer
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                fontWeight: '400',
                                fontSize: '1.125rem',
                                fontFamily: 'Inter',
                                padding: '0 2rem 7.469rem 2rem',
                                textAlign: 'left',
                                lineHeight: '1.2',
                                '@media (max-width: 1024px)': {
                                    padding: '0 1rem 1.25rem 1rem',
                                },
                            }}
                        >
                            Our therapists blend expertise with compassion, creating a trustworthy relationship.
                            Each partner is personally interviewed and rigorously screened to ensure sessions cater
                            to your needs.
                        </Typography>
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ServiceItem
                        imageSrc={Image1}
                        altText="For Individuals"
                        title="For Individuals"
                        description="Get the help you need to overcome life's challenges from our licensed therapists who specialize in a range of therapeutic approaches."
                        backgroundColor="#002936"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ServiceItem
                        imageSrc={Image2}
                        altText="For Teens"
                        title="For Teens"
                        description="Overcome challenges like anxiety, depression, and stress with the help of licensed therapists who specialize in working with teens."
                        backgroundColor="#06422D"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ServiceItem
                        imageSrc={Image3}
                        altText="For Couples"
                        title="For Couples"
                        description="Strengthen your relationship and improve your communication and connection with our online therapy for couples, provided by licensed therapists who specialize in couples therapy."
                        backgroundColor="#097191"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ServiceSection;