import React from 'react';
import { Typography, Grid } from '@mui/material';
export default function FAQHeader() {

  return <>
    <Grid container>
      <Grid item sm={3}>
        <Typography
          sx={{
            color: '#06422D',
            fontFamily: 'Inter',
            fontSize: '4rem',
            fontWeight:'550',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            margin: '4.688rem 0 5.25rem 6.25rem',
            '@media (max-width: 425px)': {
              margin: '1.5rem 6.5rem',
            }
          }}
          variant="h1"
        >
          FAQ
        </Typography>
      </Grid>
    </Grid>
  </>
}