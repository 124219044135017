import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Image from '../images/Frame 16.png'
import '../css/Slideshow.css'

const slides = [
    {
        text: "The convenience of online therapy has been a game-changer for me. I can now prioritize my mental health without disrupting my busy schedule.",
        name: "Emily Rodriguez",
    },
    {
        text: "I was skeptical at first, but the quality of care I've received through this telehealth platform has exceeded my expectations. It's just as effective as in-person therapy.",
        name: "Marcus Thompson",
    },
    {
        text: "Being able to connect with my therapist from the comfort of my own home has helped me open up more easily. I've made tremendous progress in managing my anxiety.",
        name: "Aisha Patel",
    },
    {
        text: "The user-friendly interface and seamless communication tools make each session feel personal and engaging. It's like my therapist is right here with me.",
        name: "Derek Chen",
    },
    {
        text: "As someone living in a rural area, this telehealth service has given me access to top-notch mental health care that I wouldn't have had otherwise.",
        name: "Olivia Sinclair",
    },
    {
        text: "The flexibility of scheduling sessions outside traditional office hours has been crucial for maintaining my work-life balance while prioritizing my mental health.",
        name: "Jamal Washington",
    },
    {
        text: "I appreciate the privacy and discretion that comes with online therapy. It's allowed me to seek help without feeling self-conscious about visiting a physical office.",
        name: "Sophie Larsson",
    },
    {
        text: "The ability to message my therapist between sessions has been invaluable for those moments when I need a quick check-in or words of encouragement.",
        name: "Gabriel Morales",
    },
    {
        text: "As a new mom, this platform has been a lifesaver. I can attend therapy sessions while my baby naps, without the stress of arranging childcare.",
        name: "Zoe Campbell",
    },
    {
        text: "The range of therapists available on this platform helped me find the perfect match for my needs. I've never felt more understood and supported.",
        name: "Liam O'Connor",
    },
    {
        text: "Being able to have therapy sessions in my native language, despite living abroad, has made all the difference in my mental health journey.",
        name: "Natasha Ivanov",
    },
    {
        text: "The affordability and absence of travel costs have made consistent therapy possible for me. It's an investment in myself that I can actually maintain.",
        name: "Kwame Osei",
    },
];

export default function Slideshow() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
    };

    const handlePreviousSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    return (
        <div className="slideshow-container">
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <img src={Image} alt="This is an Icon" className="img-responsive" />
            </Box>
            <Box className="slides-content">
                <IconButton className="prev-btn" onClick={handlePreviousSlide} disabled={currentSlide === 0}>
                    <svg width="85" height="26" viewBox="0 0 85 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35.1667 0.166625C35.1667 1.52696 33.8228 3.55829 32.4625 5.26329C30.7135 7.46329 28.6235 9.38279 26.2273 10.8476C24.4307 11.9458 22.2527 13 20.5 13M20.5 13C22.2527 13 24.4325 14.0541 26.2273 15.1523C28.6235 16.619 30.7135 18.5385 32.4625 20.7348C33.8228 22.4416 35.1667 24.4766 35.1667 25.8333M20.5 13L64.5 13" stroke="#303138" stroke-width="2.55847" />
                    </svg>
                </IconButton>
                <Typography className="slide-text" variant='body1' sx={{ fontFamily: 'Inter' }}>
                    {slides[currentSlide].text}
                </Typography>
                <IconButton className="next-btn" onClick={handleNextSlide} disabled={currentSlide === slides.length - 1}>
                    <svg width="85" height="26" viewBox="0 0 85 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M49.8333 0.166625C49.8333 1.52696 51.1772 3.55829 52.5375 5.26329C54.2865 7.46329 56.3765 9.38279 58.7727 10.8476C60.5693 11.9458 62.7473 13 64.5 13M64.5 13C62.7473 13 60.5675 14.0541 58.7727 15.1523C56.3765 16.619 54.2865 18.5385 52.5375 20.7348C51.1772 22.4416 49.8333 24.4766 49.8333 25.8333M64.5 13L20.5 13" stroke="#303138" stroke-width="2.39516" />
                    </svg>
                </IconButton>
            </Box>
            <div className="slide-author" >
                <Typography
                    className="author-name"
                    variant='subtitle2'
                    sx={{
                        fontWeight: '600',
                        marginTop: '2.5rem',
                        fontFamily: 'Inter',
                    }}
                >
                    {slides[currentSlide].name}
                </Typography>
            </div>
        </div>
    );
}