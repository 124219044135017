import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logoimage from '../images/logoNav.svg';
import { AppBar, Toolbar, Box } from '@mui/material';
import { styled } from '@mui/system';

const NavbarLogo = styled(Link)(({ theme }) => ({
    '@media (max-width: 375px)': {
        marginRight: '1rem',
    },
    '@media (max-width: 320px)': {
        width: '70%',
    }
}));

const NavbarLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '3.5rem',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '@media (max-width: 1024px)': {
        gap: '2.5rem',
        alignItems: 'center',
    },
    '@media (max-width: 768px)': {
        display: 'none',
    },
}));

const NavbarLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    whiteSpace: 'none',
    color: '#002936',
    fontSize: '1.125rem',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    transition: 'color 0.3s',
    '@media (max-width: 768px)': {
        display: 'none',
    },
}));

const ContactButton = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    backgroundColor: '#06422D',
    color: 'white',
    borderRadius: '0.625rem',
    fontSize: '1rem',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    transition: 'background-color 0.3s',
    textTransform: 'none',
    width: '8.375rem',
    height: '2.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: '#2EABD1',
    },
    '@media (max-width: 768px)': {
        fontSize: '0.875rem',
        padding: '0.3rem 0.6rem',
    },
}));

const CustomNavbar = () => {
    const location = useLocation();

    if (location.pathname === '/faq') {
        return (
            <AppBar position="static" sx={{ backgroundColor: '#FFFEF4', padding: '1.594rem 3rem', boxShadow: 'none' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <NavbarLogo to="/">
                        <img src={Logoimage} alt="logo"
                            style={{ width: 'auto', height: 'auto' }} />
                    </NavbarLogo>
                    <ContactButton to="https://chat.1lifeline.com" target="_blank" rel="noreferrer">
                        Sign up
                    </ContactButton>
                </Toolbar>
            </AppBar>
        );
    }
    else {
        return (
            <AppBar position="static" sx={{ backgroundColor: '#FFFEF4', padding: '1.594rem 3rem', boxShadow: 'none' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <NavbarLogo to="/">
                        <img src={Logoimage} alt="logo"
                            style={{ width: 'auto', height: 'auto' }} />
                    </NavbarLogo>
                    <NavbarLinks>
                        <a href="#Services"
                            style={{
                                textDecoration: 'none',
                                whiteSpace: 'none',
                                color: '#002936',
                                fontSize: '1.125rem',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                transition: 'color 0.3s',
                                '@media (max-width: 768px)': {
                                    display: 'none',
                                },
                            }}
                        >
                            Services
                        </a>
                        <a href="#Therapist"
                            style={{
                                textDecoration: 'none',
                                whiteSpace: 'none',
                                color: '#002936',
                                fontSize: '1.125rem',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                transition: 'color 0.3s',
                                '@media (max-width: 768px)': {
                                    display: 'none',
                                },
                            }}
                        >
                            Our Therapists
                        </a>
                        <NavbarLink to="/faq">
                            FAQ
                        </NavbarLink>
                    </NavbarLinks>
                    <ContactButton to="https://chat.1lifeline.com" target="_blank" rel="noreferrer">
                        Sign up
                    </ContactButton>
                </Toolbar>
            </AppBar >
        );
    };
}

export default CustomNavbar;