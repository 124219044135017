import React from 'react';
import { Typography, Grid } from '@mui/material';
export default function ContactUsHeader() {

  return <>
    <Grid container>
      <Typography
        sx={{
          fontFamily: 'Inter',
          margin: '3.25rem 0 3.25rem 6.25rem',
          fontSize: "1.75rem",
          display: 'flex',
          alignItems: 'left',
          fontWeight: '500',
          '@media (max-width: 1024px)': {
            margin: '2.5rem 0 2rem 2rem',
          },
        }}
        variant="h1"
      >
        Contact US
      </Typography>
    </Grid>
  </>
}