import { List, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

const faqData = [
  {
    category: 'Services Offered by 1Lifeline',
    questions: [
      'What is the pricing like?',
      'Can 1Lifeline replace traditional in-person therapy?',
    ]
  }
];

export default function FAQ1() {
  return (
    <List>
      {faqData.map((category, index) => (
        <React.Fragment key={index}
          sx={{
            width: '90%'
          }}
        >
          {category.questions.map((question, qIndex) => (
            <Accordion sx={{
              boxShadow: 'none',
              width: '90%',
              mb: 2,
              color: '#5b5d6c',
              '@media (max-width: 425px)': {
                margin: 'auto',
                mb: 2,
                width: '100%'
              },
              '&::before': {
                display: 'none'
              },
            }} key={qIndex}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#167CC8' }} />}
                aria-controls="panel1-content"
                id="panel1-header" sx={{
                  boxShadow: '2px 0px 2px 0px rgba(0,0,0,0.1),0px 0px 15px -3px rgba(0,0,0,0.1)',
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '1.125rem',
                    fontWeight: '500',
                    textAlign: 'left'
                  }}
                >{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {question === 'What is the pricing like?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    At 1Lifeline, our virtual sessions offer a more affordable option compared to in-office visits.
                    Prices will range from $65 to $100 per week, billed every 4 weeks. Moreover, you have
                    the flexibility to choose the number of sessions that accommodate your needs. We
                    maintain transparent billing for your convenience, and you can cancel your membership at
                    any time.
                  </Typography>
                ) : question === 'Can 1Lifeline replace traditional in-person therapy?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>                    While 1 Lifeline is not meant to replace traditional in-person therapy, we are a valuable
                    alternative that provides accessible and comprehensive support at your fingertips. We
                    recognize that therapy shouldn't be one size fits all as we believe in the importance of
                    personalized approaches to mental health care. We celebrate and embrace each
                    individual's unique journey toward mental and emotional clarity, empowering them to
                    develop healthy mindsets.
                  </Typography>
                ) : null}
              </AccordionDetails>
            </Accordion>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
}