import CustomNavbar from "../Components/Navbar";
import HeroSection from "../Components/Herosection";
import StatisticsSection from "../Components/StatisticsSection";
import Aboutus from './../Components/About';
import ServiceSection from "../Components/Services";
import MeetOurTherapists from "../Components/Therapists";
import Feedback from "../Components/PatientFeedback";
import ConnectUsPage from "../Components/Contact";
import Footer from "../Components/Footer";
function Homepage() {

    return (
        <div style={{ overflowX: 'hidden' }}>
            <CustomNavbar />
            <HeroSection />
            <StatisticsSection />
            <Aboutus />
            <ServiceSection />
            <MeetOurTherapists/>
            <Feedback/>
            <ConnectUsPage />
            <Footer/>
        </div>
    )
}

export default Homepage;