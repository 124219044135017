import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import HeroImage from '../images/Heroimg.png';
import '../css/fonts.css';
import CustomBtn from './Btns';

const StyledImage = styled('img')(({ theme }) => ({
    width: '100%',
    maxWidth: '52.5rem',
    order: { xs: 2, md: 2 },

}));

function HeroSection() {
    return (
        <Box
            sx={{
                width: '88%',
                margin: '2.5vw auto',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column-reverse', md: 'row' },
                alignItems: 'center',
                backgroundColor: '#FFFEF4',
            }}
        >
            <Box sx={{
                textAlign: { xs: 'center', md: 'left', xl: 'left' },
                width: { xs: '100%', md: '44%' },
                '@media (max-width: 425px)': {
                    width: '100%',
                    textAlign: 'center',
                },
            }}>
                <Typography variant="h6" component="h6" color="#1A237E" fontWeight="400"
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '1.75vw',
                        color: '#097191',
                        '@media (max-width: 425px)': {
                            fontSize: '0.875rem',
                        },
                    }}
                >
                    1LifeLine.
                </Typography>
                <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                        fontFamily: 'Recoleta, Arial, sans-serif',
                        fontSize: '4vw',
                        fontWeight: 500,
                        color: '#004D40',
                        marginTop: '1rem',
                        marginBottom: '2rem',
                        width: '100%',
                        '@media (max-width: 425px)': {
                            fontSize: '1.375rem',
                        },
                    }}
                >
                    Accessible Therapy For Everyone.
                </Typography>
                <a href="#contact">
                    <CustomBtn text="Contact Us" />
                </a>
            </Box>
            <Box sx={{
                textAlign: { xs: 'center', md: 'right' },
                width: { xs: '100%' }
            }}>
                <StyledImage src={HeroImage} alt="Accessible Therapy" />
            </Box>
        </Box>
    );
}

export default HeroSection;