import { Divider, Grid, Typography, Box, Link as MuiLink, useTheme } from "@mui/material";
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import XIcon from '@mui/icons-material/X';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import imgLogo from "../images/logo.png";
export default function Footer() {
    const theme = useTheme();
    const location = useLocation();
    if (location.pathname === '/faq') {
        return (
            <Grid container spacing={2} sx={{ backgroundColor: '#CFECEC', padding: theme.spacing(5), margin: `${theme.spacing(21)} 0 0 0`, maxWidth: 'fitContent' }}>
                <Grid item xs={12} sm={6} md={3} sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    pl: { xs: 0, sm: theme.spacing(4) }
                }}>
                    <Link to="/">
                        <img src={imgLogo} alt="website logo" style={{ width: '100%', height: 'auto' }} />
                    </Link>
                </Grid>
                <Grid item xs={12} sx={{ mt: theme.spacing(2) }}>
                    <Divider sx={{ borderColor: 'white', borderWidth: 1 }} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        pl: { xs: 0, sm: theme.spacing(4) },
                        mt: { xs: theme.spacing(2), sm: 0 }
                    }}
                >
                    <Typography variant="caption" color="textSecondary" align="center" sx={{ fontFamily: 'Inter' }}>
                        © 2024 ILifeLine. All rights reserved.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        pr: { xs: 0, sm: theme.spacing(4) },
                        mt: { xs: theme.spacing(2), sm: 0 }
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: theme.spacing(2)
                    }}>
                        <Typography variant="caption" color='#5B5D6C' align="right" sx={{ fontFamily: 'Inter' }}>
                            Coming soon!
                        </Typography>
                        <MuiLink href="#" color="inherit">
                            <XIcon fontSize="small" />
                        </MuiLink>
                        <MuiLink href="#" color="inherit">
                            <FacebookOutlinedIcon fontSize="small" />
                        </MuiLink>
                        <MuiLink href="#" color="inherit">
                            <LinkedInIcon fontSize="small" />
                        </MuiLink>
                        <MuiLink href="#" color="inherit">
                            <InstagramIcon fontSize="small" />
                        </MuiLink>
                    </Box>
                </Grid>
            </Grid>
        );
    }
    else {
        return (
            <Grid container spacing={2} sx={{ backgroundColor: '#CFECEC', padding: theme.spacing(5), margin: `${theme.spacing(21)} 0 0 0`, maxWidth: 'fitContent' }}>
                <Grid item xs={12} sm={6} md={3} sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    pl: { xs: 0, sm: theme.spacing(4) }
                }}>
                    <Link to="/">
                        <img src={imgLogo} alt="website logo" style={{ width: '100%', height: 'auto' }} />
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={9}
                    sx={{
                        fontFamily: 'Inter',
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right',
                        flexWrap: 'wrap',
                        gap: theme.spacing(2),
                        textAlign: 'center',
                        pr: { xs: 0, sm: theme.spacing(4) }
                    }}
                >
                    <a href="#Services" style={{ textDecoration: 'none', color: '#5B5D6C' }}>Services</a>
                    <a href="#Therapist" style={{ textDecoration: 'none', color: '#5B5D6C' }}>Our Therapist</a>
                    <Link to='/faq' style={{ textDecoration: 'none', color: '#5B5D6C' }}>FAQ</Link>
                </Grid>
                <Grid item xs={12} sx={{ mt: theme.spacing(2) }}>
                    <Divider sx={{ borderColor: 'white', borderWidth: 1 }} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        pl: { xs: 0, sm: theme.spacing(4) },
                        mt: { xs: theme.spacing(2), sm: 0 }
                    }}
                >
                    <Typography variant="caption" color="textSecondary" align="center" sx={{ fontFamily: 'Inter' }}>
                        © 2024 ILifeLine. All rights reserved.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        pr: { xs: 0, sm: theme.spacing(4) },
                        mt: { xs: theme.spacing(2), sm: 0 }
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: theme.spacing(2)
                    }}>
                        <Typography variant="caption" color='#5B5D6C' align="right" sx={{ fontFamily: 'Inter' }}>
                            Coming soon!
                        </Typography>
                        <MuiLink href="#" color="inherit">
                            <XIcon fontSize="small" />
                        </MuiLink>
                        <MuiLink href="#" color="inherit">
                            <FacebookOutlinedIcon fontSize="small" />
                        </MuiLink>
                        <MuiLink href="#" color="inherit">
                            <LinkedInIcon fontSize="small" />
                        </MuiLink>
                        <MuiLink href="#" color="inherit">
                            <InstagramIcon fontSize="small" />
                        </MuiLink>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}