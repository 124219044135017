import { List, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

const faqData = [
  {
    category: 'Services Offered by 1Lifeline',
    questions: [
      'What methods of communication are available with my therapist?',
      'How long will each appointment last?',
      'What can I expect in my first therapy session?',
      'What is your cancellation policy?',
      'Am I able to review past messages from my therapist?',
      'What is the timeframe for using 1Lifeline?',
      'Is 1Lifeline accessible for disabled users on the web?',
    ]
  }
];

export default function FAQ2() {
  return (
    <List>
      {faqData.map((category, index) => (
        <React.Fragment key={index}
          sx={{
            width: '90%'
          }}
        >
          {category.questions.map((question, qIndex) => (
            <Accordion sx={{
              boxShadow: 'none',
              width: '90%',
              mb: 2,
              color: '#5b5d6c',
              '@media (max-width: 425px)': {
                margin: 'auto',
                mb: 2,
                width: '100%'
              },
              '&::before': {
                display: 'none'
              },
            }} key={qIndex} >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#167CC8' }} />}
                aria-controls="panel1-content"
                id="panel1-header" sx={{
                  boxShadow: '2px 0px 2px 0px rgba(0,0,0,0.1),0px 0px 15px -3px rgba(0,0,0,0.1)',
                  border: 'none',
                }}>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '1.125rem', fontWeight: '500', textAlign: 'left' }}>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {question === 'What methods of communication are available with my therapist?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    We offer four convenient methods of communication with your therapist at 1Lifeline,
                    providing you with options that align with your preferences and schedule. You can choose
                    between messaging, online live chatting, phone calls, and video conferencing. This way,
                    you can engage in therapy using the method that works best for you at any given time.
                    <br />
                    ● Messaging features
                    Our HIPAA-compliant messaging feature offers a secure therapy room to discuss personal
                    issues conveniently from anywhere and anytime, without real-time scheduling. You can
                    communicate with your therapist 24/7, and they will read and respond to your messages.
                    You'll receive email notifications for their replies.
                    <br />
                    ● Live chat sessions
                    Live chat sessions offer you the opportunity to receive instant responses and feedback
                    from your therapist in real time. To set up a live chat, scheduling a session time with your
                    therapist will be required.
                    <br />
                    ● Live phone sessions
                    Engaging in live phone sessions is easy at 1 Lifeline. You can simply use your cell phone
                    to speak with your therapist. To initiate a phone call session, you'll need to schedule a
                    time and log in to your therapy room.
                    <br />
                    ● Live video sessions
                    For those who prefer face-to-face interaction, we also offer live video sessions through a
                    virtual platform like Zoom. To have a video conference with your therapist, you'll need to
                    schedule a time and log in to your therapy room for the session.
                  </Typography>
                ) : question === 'How long will each appointment last?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    Each session will be 45 minutes. For consistency and convenience, appointments are
                    usually scheduled on the same day and time every week. If needed, we can also
                    accommodate flexible scheduling to find the best time for you.
                  </Typography>
                ) : question === 'What can I expect in my first therapy session?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    The first session will be a chance for us to get to know you better! Your therapist will give
                    you a brief assessment to evaluate your personal history, underlying issues, and desired
                    results for your therapeutic process. This is an opportunity for us to answer any questions
                    or concerns you may have, and develop a tailored plan to meet your individual needs.
                  </Typography>
                ) : question === 'What is your cancellation policy?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    We completely understand that life can get unpredictable, and if you ever need to cancel
                    or reschedule an appointment, we kindly request a minimum of 24 business hours notice.
                    If a cancellation occurs within that 24-hour window, the session fee will be charged, as it
                    helps support our commitment to providing timely care for all clients.
                  </Typography>
                ) : question === 'Am I able to review past messages from my therapist?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    Absolutely! You can always log in to your account and access your complete message
                    history with your therapist. This feature allows you to reflect on past guidance and insights
                    you received, aiding you in your progress toward self-improvement.
                  </Typography>
                ) : question === 'What is the timeframe for using 1Lifeline?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    The timeframe for using 1 Lifeline is entirely based on your individual needs. Each
                    person's situation varies; some may find that a few weeks of sessions are sufficient, while
                    others may benefit from a more extended period. We do recommend multiple sessions,
                    as they can lead to satisfactory results and provide you with the maximum benefit from
                    our services.
                  </Typography>
                ) : question === 'Is 1Lifeline accessible for disabled users on the web?' ? (
                  <Typography sx={{
                    fontFamily: 'Inter',
                    textAlign: 'left'
                  }}>
                    1Lifeline is fully committed to providing an accessible web experience for all users which
                    will be coming very soon. If you're using a desktop browser, you can enable and launch
                    accessibility preferences. Additionally, our platform supports the default accessibility
                    features of mobile devices, ensuring a smooth experience for disabled users.
                  </Typography>
                ) : null}
              </AccordionDetails>
            </Accordion>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
}