import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Image1 from "../images/Aaliyah Nkosi, LMHC.png";
import Image2 from "../images/Dr. Alejandro Reyes, Psy.D..png";
import Image3 from "../images/Dr. Evelyn Cho, Ph.D..png";
import Image4 from "../images/Dr. Ingrid Johansson, Ph.D..png";
import Image5 from "../images/Dr. Samantha Blackwood, Psy.D..png";
import Image6 from "../images/Dr. Yuki Tanaka, M.D. (Psychiatrist).png";
import Image7 from "../images/Rafael Mendoza, LMFT.png";
import Image8 from "../images/Tariq Hassan, LCSW.png";
import Image9 from "../images/Xavier Dupont, LPC.png";

const StyledPaper = styled(Paper)(({ theme, backgroundColor }) => ({
    textAlign: "center",
    height: "23.8rem",
    backgroundColor: backgroundColor,
    color: "#FEFEFE",
    borderRadius: "15px",
}));

const TherapyItem = ({ imageSrc, altText, name, degree, backgroundColor }) => {
    return (
        <StyledPaper backgroundColor={backgroundColor}>
            <img src={imageSrc} alt={altText} style={{ width: '100%', height: 'auto', borderRadius: '15px' }} />
            <Typography variant="h5" component="h2" gutterBottom sx={{
                fontSize: '1.125rem', fontWeight: '500', fontFamily: 'Inter', textAlign: 'left', padding: '1.5rem 0 0 2rem',
                '@media (max-width: 768px)': {
                    padding: '1rem 0 0 2rem',
                    margin: '0',
                },
                '@media (max-width: 425px)': {
                    padding: '0.5rem 0 0.3rem 2rem',
                    margin: '0',
                },
            }}>
                {name}
            </Typography>
            <Typography variant="body1" sx={{
                fontSize: '1rem', fontWeight: '300', fontFamily: 'Inter', textAlign: 'left', padding: '1rem 2rem 0', lineHeight: '1.2',
                '@media (max-width: 425px)': {
                    padding: '0 0 0 2rem',
                }
            }}>
                {degree}
            </Typography>
        </StyledPaper>
    );
};

const initialTherapistData = [
    {
        imageSrc: Image1,
        altText: "Aaliyah Nkosi",
        name: "Aaliyah Nkosi",
        degree: "LMHC",
        backgroundColor: "#097191"
    },
    {
        imageSrc: Image2,
        altText: "Dr. Alejandro Reyes",
        name: "Dr. Alejandro Reyes",
        degree: "Psy.D.",
        backgroundColor: "#06422D"
    },
    {
        imageSrc: Image3,
        altText: "Dr. Evelyn Cho",
        name: "Dr. Evelyn Cho",
        degree: "Ph.D.",
        backgroundColor: "#002936"
    }
];

const additionalTherapistData = [
    {
        imageSrc: Image4,
        altText: "Dr. Ingrid Johansson, Ph.D.",
        name: "Dr. Ingrid Johansson",
        degree: "Ph.D.",
        backgroundColor: "#097191"
    },
    {
        imageSrc: Image5,
        altText: "Dr. Samantha Blackwood, Psy.D.",
        name: "Dr. Samantha Blackwood",
        degree: "Psy.D.",
        backgroundColor: "#06422D"
    },
    {
        imageSrc: Image6,
        altText: "Dr. Yuki Tanaka, M.D. (Psychiatrist)",
        name: "Dr. Yuki Tanaka",
        degree: "M.D. (Psychiatrist)",
        backgroundColor: "#002936"
    }
];

const newTherapistData = [
    {
        imageSrc: Image7,
        altText: "Rafael Mendoza, LMFT",
        name: "Rafael Mendoza",
        degree: "LMFT",
        backgroundColor: "#097191"
    },
    {
        imageSrc: Image8,
        altText: "Tariq Hassan, LCSW",
        name: "Tariq Hassan",
        degree: "LCSW",
        backgroundColor: "#06422D"
    },
    {
        imageSrc: Image9,
        altText: "Xavier Dupont, LPC",
        name: "Xavier Dupont",
        degree: "LPC",
        backgroundColor: "#002936"
    }
];
const MeetOurTherapists = () => {
    const [therapistsIndex, setTherapistsIndex] = useState(0);
    const [therapistData, setTherapistData] = useState(initialTherapistData);

    useEffect(() => {
        const timer = setInterval(() => {
            setTherapistsIndex((prevIndex) => (prevIndex + 1) % 3);

            if (therapistsIndex === 0) {
                setTherapistData(initialTherapistData);
            } else if (therapistsIndex === 1) {
                setTherapistData(additionalTherapistData);
            } else {
                setTherapistData(newTherapistData);
            }
        }, 3000);

        return () => {
            clearInterval(timer);
        };
    }, [therapistsIndex]);
    return (
        <div style={{ padding: '5vh 0', width: '90%', margin: '2rem auto' }} id="Therapist">
            <Typography
                variant="h1"
                component="h1"
                sx={{
                    fontFamily: "Inter",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                    color: "#303138",
                    textAlign: "left",
                    padding: '2vh 0',
                    margin: '0 0 2.5rem 0',
                }}
            >
                Meet Our Therapists
            </Typography>
            <Grid
                container
                spacing={2} justifyContent="center" sx={{
                    backgroundColor: "#FEFEFE",
                }}
            >
                {therapistData.map((therapist, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                        <TherapyItem
                            imageSrc={therapist.imageSrc}
                            altText={therapist.altText}
                            name={therapist.name}
                            degree={therapist.degree}
                            backgroundColor={therapist.backgroundColor}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={3}>
                    <StyledPaper backgroundColor="#963A0F">
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: '700',
                                fontFamily: 'Inter',
                                padding: '3.281rem 0.5rem 1.25rem 2rem',
                                textAlign: 'left',
                                '@media (max-width: 1024px)': {
                                    padding: '3rem 0.5rem 0.5rem 1rem',
                                },
                                '@media (max-width: 768px)': {
                                    padding: '5rem 0 1.25rem 2rem',
                                },
                            }}
                        >
                            We have the best team.
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                fontWeight: '400',
                                fontSize: '1.125rem',
                                fontFamily: 'Inter',
                                padding: '0 2rem 7.469rem 2rem',
                                textAlign: 'left',
                                lineHeight: '1.2',
                                '@media (max-width: 1024px)': {
                                    padding: '0 0 0.75rem 1rem',
                                },
                                '@media (max-width: 768px)': {
                                    padding: '0 0.5rem 0 2rem',
                                },
                            }}
                        >
                            We prioritize therapists who blend clinical skill with human
                            qualities to earn your trust. We personally interview and
                            rigorously screen every potential partner to guarantee top-notch
                            care for our clients.
                        </Typography>
                    </StyledPaper>
                </Grid>
            </Grid>
        </div>
    );
};

export default MeetOurTherapists;