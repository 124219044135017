
import { Grid } from "@mui/material";
import FAQHeader from "../Components/FAQHeader";
import FAQSection from "../Components/FAQSection";
import Footer from "../Components/Footer";
import CustomNavbar from "../Components/Navbar";

export default function FAQPage() {
    return (
        <>
            <CustomNavbar/>
            <Grid container sx={{ background: '#FFFEF4', overflowX: 'hidden' }}>
                <Grid item xs={12}>
                    <FAQHeader />
                </Grid>
                <Grid item xs={12}>
                    <FAQSection />
                </Grid>
                <Grid item sm={12}>
                    <Footer />
                </Grid>
            </Grid>
        </>
    )
}
